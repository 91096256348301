.asset-library-container {
    
}

.asset-library-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(0, 1fr);
    gap: 10px;
    padding: 10px;
}

.asset-library-grid-image {
    background-color: lightgray;
    border-radius: 5px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.asset-library-grid-image:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    background-color: #aa69ff35;
    transform: scale(1.1);
}