.test{
    background-color: salmon;
    height: 95%;
}
.blockly-generator{
    display: flex;
    background-color: aqua;
    height: 94%;
}
.blockly-generator__container--left-side{
    width: 30%;
    background-color: red;
}
.blockly-generator__container--right-side{
    width: 70%;
    min-width: 30%;
    background-color: blue;
}
.blockly-generator__container--simulation-window{
    height: 46%;
    background-color: blue;

}
.blockly-generator__container--blank{
    height: 46%;
    background-color: green;

}
.blockly-generator__container--footer-buttons{
    height: 8%;
    background-color: rgb(55, 55, 55);
}