.entity-builder__body{
    height: 95%;
    width: 100%;
    position: absolute;
}

.entity-builder-container {
    /* display: grid;
    grid-template-columns: repeat(6, 16.66%); */

    /* TODO */
    height: 100%;
    width: 100%;
    background-color: lime;
}

.entity-builder-settings-container {
    border-left: 1px solid black;
    font-weight: bold;
    background-color: antiquewhite;
    grid-column: 2 / 4;
    height: 100%;
    overflow-y: auto;
}

.simulationWindow {
    grid-column: 4 / 7;
    height: 100%;
}

.mesh-settings {
    font-weight: bold;
    background-color: aquamarine;
}

.systems-settings {
    font-weight: bold;
    background-color: cadetblue;
}

.filter-settings {
    font-weight: bold;
    background-color: violet;
}

.hitbox-settings {
    background-color: tomato;
    font-weight: bold;
}

.systems-checkbox-container {
    display: flex;
    margin-left: 10px;
    font-weight: normal;
}

.hitbox-size-container {
    display: flex;
    font-weight: normal;
    margin-left: 10px;
}

.hitbox-size-input {
    margin-left: 10px;
    width: 50px;
}

.hitbox-mass-container {
    display: flex;
    font-weight: normal;
    margin-left: 10px;
}

.hitbox-mass-input {
    margin-left: 10px;
    width: 50px;
}

.filter-group-container {
    display: flex;
    font-weight: normal;
    margin-left: 10px;
}

.filter-group-input {
    margin-left: 10px;
    width: 50px;
}

.filter-mask-container {
    display: flex;
    font-weight: normal;
    margin-left: 10px;
}

.filter-mask-input {
    margin-left: 10px;
    width: 50px;
}

.filter-warning {
    margin-left: 10px;
    font-weight: normal;
    font-size: 12px;
    color: yellow;
}

.mesh-settings-add-new-mesh {
    margin-left: 10px;
    font-weight: normal;
}

.individual-mesh-settings {
    background-color: bisque;
    margin-left: 10px;
    font-weight: normal;
}

.mesh-scale-container {
    display: flex;
    font-weight: normal;
}

.mesh-scale-input {
    margin-left: 10px;
    width: 50px;
}

.mesh-position-offset-container {
    display: flex;
    font-weight: normal;
}

.mesh-position-offset-input {
    margin-left: 10px;
    width: 50px;
}

.mesh-rotation-offset-container {
    display: flex;
    font-weight: normal;
}

.mesh-rotation-offset-input {
    margin-left: 10px;
    width: 50px;
}