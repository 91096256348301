/* Styling for the button on the drawer, resizing */
/* #ui-builder__drawer-button--open, #ui-builder__drawer-button--closed {
    background-color: #000000;
    border: none;
    
    height: 100%;
    width: 1px;
    top: 5%;

    z-index: 10;
    position: absolute;

    cursor: ew-resize;
} */

/* #ui-builder__drawer-button--open{
    top: 0%;
    left: 15vw;
} */

/* Styling for the burger button opening and closing the drawer */
/* #ui-builder__burger-button_image--closed{
    position: absolute;

    top: 2rem;
    left: 2rem;

    height: 5%;
    width: auto;

    border: none;
    border-radius: 0;

    z-index: 1000;
} */

/* #ui-builder__burger-button_image--open{
    height: 100%;
    width: auto;
} */

/* Styling for the drawer itself */
/* #ui-builder__drawer {
    background-color: #252526;

    height: 100%;

    position: absolute;
    top: 0%;
    left: 0px;
} */

/* .ui-builder__drawer__tab-button{
    border: none;

    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px; 
    color: #000000;
}

.ui-builder__drawer__tab-button:hover{
    background-color: #ACACAC;
} */

#ui-builder__drawer__save-button:active, #ui-builder__drawer__add-template-button:active {
    background-color: #3291ff;
    color: #ffffff;
}