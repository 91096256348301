/* Modal Styling */
.ui-builder__template-modal {
    position: fixed;
    top: 20vh;
    left: 35vw;
    width: 30vw;

    background-color: #3A3A3A;
    border-radius: 10px;

    box-sizing: border-box;
    padding: 2.5% 3% 2.5% 3%;

    align-items: center;
    justify-content: center;

    /* Align children one of top of each other */
    display: flex;
    flex-direction: column;
}

#ui-builder__template-modal__end {
    width: 100%;
    margin: 5% 0% 0% 0%;
}

#ui-builder__template-modal__title {
    /* Text Styling */
    font-weight: bold;
    color: #ffffff;
    font-size: 26px;

    /* Vertical Allign */
    display: flex;
    align-items: center;
    justify-content: center;
}

#ui-builder__template-modal__search {
    width: 100%;
    box-sizing: border-box;

    border: 2px solid #000000;
    outline: none;
    font-size: 20px;
    border-radius: 5px;
    padding: 5px;
}

/* End elements, cancel and create */
#ui-builder__template-modal__end {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#ui-builder__template-modal__end__cancel,
#ui-builder__template-modal__end__confirm {
    width: 25%;
    height: 100%;
    border: none;
    font-size: 20px;
    border-radius: 5px;
    padding: 5px;
}

#ui-builder__template-modal__end__cancel {
    background-color: #ffffff;
    color: #000000;
}


#ui-builder__template-modal__end__confirm {
    background-color: #3291ff;
    color: #ffffff;
}

/* Modal Tabs Styling */
.ui-builder__template-modal__tab{
    margin: 3% 0% 5% 0%;

    width: 45%;
    border: none;
    border-radius: 5px;
    padding: 5px;

    font-size: 20px;
    font-weight: bold;
}
.ui-builder__template-modal__tab--active{
    background-color: #ACACAC;
    color: #000000;
}

.ui-builder__template-modal__tab--not-active{
    background-color: #ffffff;
    color: #000000;
}

#ui-builder__template-modal__delete-label{
    box-sizing: border-box;
    /* background-color: aquamarine; */
    font-size: 20px;
    color: #ffffff;
}