/* Activity Settings */

/* #activity-settings__container{
    overflow: "scroll";
    height: "100vh";
} */

#activity-settings__directional-light{
    display: "flex";
    justify-content: space-between;
}

/* Directional Light */

#directional-light-input{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 12px;
    margin: 16px 8px;
}

/* Title styling */
#directional-light-input__container-title {
    display: flex;
    width: "100%";
}

#directional-light-input__container-title__label{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

/* #directional-light-input__container-title__button {
    border-radius: 50%;
    border-width: 0px;
} */

/* Body styling */
#directional-light-input__container-body-position {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 12px;
    margin: 16px 8px;
    width: 90%;
}

.directional-light-input__container-body-position__input{
    height: 70%;
    width: 40%;
    cursor: pointer;
}

#directional-light-input__container-body-color {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center vertically */
    box-sizing: border-box;
    margin: 16px 8px;
    width: 90%;
}
/* 
#directional-light-input__container-body-color__input {
    height: 100%;
    width: 40%;
    cursor: pointer;
} */