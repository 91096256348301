#label-dropddown__button {
    color: #000000;
    background-color: #FFFFFF;

    border: none;

    width:100%;
    height:100%;

    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    box-sizing: border-box;
    padding: 4px 6px 4px 6px;
}

#label-dropddown__dropdown {
    position: relative;
    display: inline-block;

    height: 100%;
}
  
#label-dropddown__dropdown-content {
    display: none;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
  
#label-dropddown__dropdown:hover #label-dropddown__dropdown-content {display: block;}

.label-dropddown__dropdown-content__button {
    height: 100%;
    width: 100%;

    font-size: small;
    text-align: left;

    border-radius: 0px;
    border: none;
    box-sizing: border-box;
    padding: 4px 6px 4px 6px;

    background-color: #FFFFFF;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.label-dropddown__dropdown-content__button:hover {
    background-color: #ACACAC;
}

/* Post css inline ticket */
#label-dropdown__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
    box-sizing: border-box;
    gap: 12px;
    margin: 16px 8px;
}

#label-dropdown__container__label{
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

#label-dropdown__container__right{
    height: 100%;
    width: 40%;
    cursor: pointer;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}