#collapse-div__header__button__drop-down, 
#collapse-div__header__button__title {
    background-color: #252526;
    border: none;
}

#collapse-div__header__button__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    color: #FFFFFF;
}