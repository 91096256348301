/* Button Item */

#ui-builder__drawer__add-template-button {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Button List */

#ui-buider__template-library__template{
    display: flex;
    align-items: center;
    height: 26px;
    box-sizing: border-box;
    padding: 20px 16px;
}

.button-list__button {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    padding: 0px 8px;
    border: none;
    background: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.button-list__button--selected {
    color: #3291ff;
}

.button-list__button--unselected {
    color: #FFFFFF;
}

/* CheckboxLabel */
#checkboxField{
    height: 70%;
    cursor: pointer;
}

#labelField{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

/* CheckboxList */
#checkbox-list__div{
    display: flex;
    align-items: center;
    height: 26px;
    box-sizing: border-box;
    gap: 12px;
    margin: 16px 8px;
}

#checkbox-list__input{
    height: 100%;
    cursor: pointer;
}

#checkbox-list__label{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

/* InputColour */
#input-colour__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
    box-sizing: border-box;
    gap: 12px;
    margin: 16px 8px;
}

#input-colour__container__label {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.input-colour__container__input {
    height: 70%;
    width: 40%;
    cursor: pointer;
}